<template>
  <div>
    <a-modal v-model:visible="visible" title="" :footer="null" width="700px" :closable="false">
      <div class="model-box">
        <div class="header">
          <img src="@/assets/images/closable-icon.png" alt="" @click="close" />
        </div>
        <div class="content">
          <div class="item">
            <div class="title">
              <div></div>
              <p>平台快速回收</p>
            </div>
            <div class="rule">
              <p>平台快速回收，极速到账</p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <div></div>
              <p>平台帮卖</p>
            </div>
            <div class="rule">
              <p>平台帮卖，同时支持商户极速回收</p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <div></div>
              <p>担保中介</p>
            </div>
            <div class="rule">
              <p>约好交易走平台，费率低</p>
            </div>
          </div>
          <div class="footer">
            <div @click="gopage('recovery')">平台快速回收</div>
            <div @click="sellClick">平台帮卖</div>
            <div @click="gopage('medium')">担保中介</div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script setup>
import { ref, onMounted, defineExpose } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
const visible = ref(false)
function close() {
  visible.value = false
}
function sellClick() {
  router.push({ path: '/allGame', query: { type: 'sell' } })
  visible.value = false
}
const gopage = (val) => {
  router.push({ path: '/retrieve', query: { type: val } })
  visible.value = false
}
onMounted(() => {})
defineExpose({ visible })
</script>
<style lang="less" scoped>
.model-box {
  .header {
    height: 54px;
    line-height: 54px;
    text-align: right;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 12px 12px 0 0;
    padding-right: 20px;
    cursor: pointer;
  }
  .content {
    padding: 0 26px;
    padding-bottom: 44px;
    .item {
      margin-top: 26px;
      .title {
        display: flex;
        align-items: center;
        div {
          width: 6px;
          height: 19px;
          background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
          border-radius: 3px;
          margin-top: 3px;
        }
        p {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #343434;
          margin-left: 5px;
        }
      }
      .rule {
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    div {
      width: 198px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-radius: 30px;
      cursor: pointer;
    }
    div:nth-child(1) {
      background: linear-gradient(135deg, #ffa944 0%, #fe7600 100%);
    }
    div:nth-child(2) {
      background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    }
    div:nth-child(3) {
      background: linear-gradient(135deg, #bc6eff 0%, #816dff 100%);
    }
  }
}
</style>
