<template>
  <header class="app-header">
    <div class="wrap">
      <div class="box">
        <h1 class="logo"><RouterLink to="/">收游帮</RouterLink></h1>
        <div>
          <div class="search">
            <div style="display: flex; align-items: center">
              <el-select
                v-model="selectValue"
                style="width: 150px; height: 42px"
                @change="handleChange"
                placeholder="选择游戏"
                value-key="id"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
              <el-divider direction="vertical" />
            </div>
            <div style="display: flex">
              <input
                type="text"
                placeholder="请输入游戏和商品名称"
                class="search-input"
                v-model="searchValue"
              />
              <p class="search-btn" @click="searchClick">搜索</p>
            </div>
          </div>
          <div class="hot">
            <div class="hot-content">
              热门搜索:
              <text @click="gopage(i)" v-for="i in hotSearchList" :key="i">{{ i.name }}</text>
            </div>
          </div>
        </div>
      </div>
      <div class="header-nav" v-if="navShow === true">
        <div class="header-nav-sidebar">
          <p class="left">热门游戏</p>
          <div class="right" @click="pagesClick">
            <text>全部游戏</text>
            <img src="@/assets/images/icon-right.png" alt="" />
          </div>
        </div>
        <div class="header-nav-tabs">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            class="tabs-item"
            :class="current == index ? 'tabs-bg' : ''"
            @mouseover="mouseOver(index)"
            @mouseleave="mouseLeave"
            @click="tabClick(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <sellModal ref="sellVisible"></sellModal>
    <biddingModal ref="biddingVisible"></biddingModal>
  </header>
</template>

<script setup>
import sellModal from '@/components/SellModal/index.vue'
import biddingModal from '@/components/BiddingModal/index.vue'
import { ref, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { gameList, hotSearch } from '@/api/home'
import { ElMessage } from 'element-plus'
const route = useRoute()
const navShow = ref(true)
const router = useRouter()
const options = ref([])
const tabs = ref(['首页', '竞价专区', '我要买', '我要卖', '鉴定大厅', '下载APP'])
const current = ref(0)
const sellVisible = ref(null)
const biddingVisible = ref(null)
const selectValue = ref('')
const searchValue = ref('')
const hotSearchList = ref([])
function mouseOver(index) {
  current.value = index
}
function mouseLeave() {
  current.value = 0
}
function tabClick(e) {
  if (e === '我要卖') {
    sellVisible.value.visible = true
  } else if (e === '竞价专区') {
    biddingVisible.value.visible = true
  } else if (e === '鉴定大厅') {
    router.push('/appraisal')
  } else if (e === '首页') {
    router.push('/')
  } else if (e === '我要买') {
    router.push({ path: '/allGame', query: { type: 'buy' } })
  } else if (e === '下载APP') {
    router.push('/download')
  }
}
function pagesClick() {
  router.push({ path: '/allGame', query: { type: 'buy' } })
}
const getList = () => {
  const params = {
    type: -1
  }
  gameList(params).then((res) => {
    if (res.code == 200) {
      options.value = res.data.list
    }
  })
}
getList()
//热门搜索
const getHotSearch = () => {
  hotSearch().then((res) => {
    if (res.code == 200) {
      hotSearchList.value = res.data
    }
  })
}
getHotSearch()
const gopage = (i) => {
  selectValue.value = i.name
  router.push({ path: '/wares', query: { id: i.id, type: 'buy' } })
}
const searchClick = () => {
  if (!searchValue.value) {
    ElMessage.warning('请输入搜索内容')
    return
  }
  if (!selectValue.value) {
    ElMessage.warning('请选择游戏')
    return
  }
  console.log(selectValue.value)
  console.log(route)
  if (route.path === '/wares') {
  } else {
    router.push({
      path: '/wares',
      query: {
        gameName: selectValue.value.name,
        id: selectValue.value.id,
        type: 'buy',
        title: searchValue.value
      }
    })
  }
}
onMounted(() => {
  if (route.path !== '/') {
    navShow.value = false
  } else {
    navShow.value = true
  }
})
watch(route, (val) => {
  if (val.path !== '/') {
    navShow.value = false
  } else {
    navShow.value = true
  }
})
</script>

<style scoped lang="less">
.app-header {
  background: #fff;
  .wrap {
    width: 1200px;
    margin: 0 auto;
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .logo {
      width: 200px;
      a {
        display: block;
        height: 132px;
        width: 100%;
        text-indent: -9999px;
        background: url('~@/assets/images/logo.png') no-repeat center 18px / contain;
      }
    }
    .search {
      // width: 802px;
      border: 0;
      position: relative;
      display: flex;
      justify-content: space-between;
      border: 1px solid rgba(6, 141, 255, 1);
      border-radius: 21px;
      // border-image: linear-gradient(
      //     135deg,
      //     rgba(6, 141, 255, 1),
      //     rgba(13, 184, 255, 1)
      //   )
      //   2 2;
      :deep(.el-select) {
        border-radius: 21px 0 0 21px;
      }
      :deep(.el-input__wrapper) {
        height: 42px !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 21px 0 0 21px;
      }
      :deep(.el-input__inner) {
        text-align: center;
      }
      :deep(.el-divider) {
        border-left: 1px solid #b3b3b3;
        height: 18px;
      }
      .search-btn {
        width: 124px;
        height: 42px;
        line-height: 42px;
        background: #058aff;
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        border-radius: 21px;
        cursor: pointer;
      }
      .search-input {
        width: 502px;
      }
    }
    /deep/ .ant-select-selection-search-input {
      height: 42px !important;
      line-height: 42px !important;
    }
    /deep/ .ant-select-selection-placeholder {
      text-align: center;
      line-height: 42px;
    }
    /deep/ .ant-select-selection-item {
      text-align: center;
      line-height: 42px;
    }
    .hot {
      margin-top: 10px;
      .hot-content {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #b3b3b3;
        text:hover {
          color: #00b6fa;
          cursor: pointer;
        }
        text:not(:first-child) {
          margin-left: 5px;
        }
      }
    }
    .header-nav {
      display: flex;
      .header-nav-sidebar {
        width: 238px;
        height: 38px;
        background: url('~@/assets/images/nav-bg.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 24px;
        line-height: 38px;
        .left {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .right {
          text {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
          }
          img {
            margin-left: 5px;
          }
        }
      }
      .header-nav-tabs {
        width: 1010px;
        display: flex;
        justify-content: flex-start;
        .tabs-item {
          width: 16.666%;
          line-height: 38px;
          text-align: center;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #313133;
          cursor: pointer;
        }
        .tabs-bg {
          color: #00b6fa;
        }
      }
    }
  }
}
</style>
