import request from '@/utils/request'

// 获取菜单列表
export const findHeadCategory = () => request('home/category/head', 'GET', {})

/**
 * @description: 获取banner图
 * @param {*}
 * @return {*}
 */
export const findBanner = (type) => {
  return request('api/banner', 'get', { type })
}
// 金刚区游戏分类列表
export const game = () => request('api/game', 'GET', {})
// 游戏分类列表
export const gameList = (data) => request('api/game_list', 'GET', data)
// 商品列表
export const getGoods = (data) => request('api/goods', 'post', data)
// 成交速报
export const notice = (data) => request('api/notice', 'GET', data)
// 热门搜索
export const hotSearch = (data) => request('api/hot_search_game', 'GET', data)
// 下载APP
export const version = (data) => request('api/version', 'GET', data)
