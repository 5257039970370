<template>
  <footer class="app_footer">
    <div class="extra">
      <div class="wrap">
        <div class="slogan">
          <a href="javascript:;" @click="goPage(1)">
            <span>我要卖号</span>
          </a>
          <a href="javascript:;" @click="goPage(2)">
            <span>我要买号</span>
          </a>
          <a href="javascript:;" @click="goPage(3)">
            <span>鉴定大厅</span>
          </a>
          <a href="javascript:;" @click="goPage(4)">
            <span>个人中心</span>
          </a>
        </div>
        <div class="copyright">
          <p>
            <a href="javascript:;" @click="goPage(5)">网站首页</a>
            <a href="javascript:;" @click="goPage(6)">关于我们</a>
            <a href="javascript:;" @click="goPage(7)">广告合作</a>
            <a href="javascript:;" @click="goPage(8)">联系我们</a>
            <a href="javascript:;" @click="goPage(9)">隐私条款</a>
            <a href="javascript:;" @click="goPage(10)">免责声明</a>
          </p>
          <p>苏ICP备2023016522号-1</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()
const goPage = (val) => {
  if (val === 1) {
  } else if (val === 2) {
    router.push({ path: '/allGame', query: { type: 'buy' } })
  } else if (val === 3) {
    router.push('/appraisal')
  } else if (val === 4) {
    router.push({ path: '/user', query: { current: 0 } })
  } else if (val === 5) {
    router.push('/')
  } else if (val === 6) {
    router.push({ path: '/article', query: { id: 8 } })
  } else if (val === 7) {
    router.push({ path: '/article', query: { id: 6 } })
  } else if (val === 8) {
    router.push({ path: '/article', query: { id: 18 } })
  } else if (val === 9) {
    router.push({ path: '/article', query: { id: 2 } })
  } else if (val === 10) {
    router.push({ path: '/article', query: { id: 19 } })
  }
}
</script>

<style scoped lang="less">
.app_footer {
  height: 219px;
  overflow: hidden;
  background-color: #333;
  // padding-top: 20px;
  .wrap {
    width: 1200px;
    margin: 0 auto;
    .extra {
      background-color: #333;
    }
    .slogan {
      padding: 0 336px;
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      a {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        i {
          font-size: 50px;
          vertical-align: middle;
          margin-right: 10px;
          font-weight: 100;
        }
        span {
          vertical-align: middle;
          text-shadow: 0 0 1px #333;
        }
      }
    }
    .copyright {
      height: 170px;
      padding-top: 40px;
      text-align: center;
      color: #999;
      font-size: 15px;
      p {
        line-height: 1;
        margin-bottom: 20px;
      }
      a {
        color: #999;
        line-height: 1;
        padding: 0 5px;
        border-right: 1px solid #999;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
</style>
