<template>
  <TopNav>顶部通栏</TopNav>
  <Header>头部</Header>
  <!-- 吸顶操作 -->
  <!-- <HeaderSticky></HeaderSticky> -->
  <main>
    <router-view></router-view>
  </main>
  <AppFooter>底部</AppFooter>
</template>

<script setup>
import TopNav from './components/top-nav.vue'
import Header from './components/top-header.vue'
import AppFooter from './components/layout-footer.vue'
import HeaderSticky from './components/app-header-sticky.vue'
import { onMounted, watch, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const store = useStore()
const router = useRouter()
onMounted(() => {})
</script>

<style scoped lang="less"></style>
