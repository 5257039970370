<template>
  <nav class="app-topnav">
    <div class="wrap">
      <div class="left" v-if="loginStatus === false">
        <p @click="goPages(0)">Hi，欢迎来到收游帮</p>
        <p style="cursor: pointer" @click="loginVisibleClick(1)">请登录</p>
        <p style="cursor: pointer" @click="loginVisibleClick(2)">免费注册</p>
      </div>
      <div class="left" v-else>
        <p>Hi，{{ userInfo.nickname }}，欢迎来到收游帮</p>
        <p style="cursor: pointer" @click="LogOut">注销</p>
      </div>
      <div class="right">
        <ul>
          <li><a href="javascript:;" @click="goPages(1)">个人中心</a></li>
          <li><a href="javascript:;" @click="goPages(2)">我的收藏</a></li>
          <li><a href="javascript:;" @click="goPages(3)">帮助中心</a></li>
          <li><a href="javascript:;" @click="goPages(4)">联系客服</a></li>
        </ul>
      </div>
    </div>
  </nav>
  <login ref="loginModal"></login>
  <el-dialog v-model="LogOutDialog" title="退出登录" width="30%" :before-close="handleClose">
    <span>您确定要退出吗？</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="LogOutDialog = false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import login from '@/components/Login/index.vue'
import { ref, onMounted, watch, reactive, inject } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import { fa } from 'element-plus/es/locale'
import { getChatService } from '@/api/service'
const store = useStore()
const router = useRouter()
const loginModal = ref(null)
const LogOutDialog = ref(false)
const userInfo = ref({})
const loginStatus = ref(false)
const reload = inject('reload')
function loginVisibleClick(e) {
  if (e === 1) {
    loginModal.value.visible = true
    loginModal.value.loginVisible = true
  } else if (e === 2) {
    loginModal.value.visible = true
    loginModal.value.registerVisible = true
    loginModal.value.loginVisible = false
    loginModal.value.registerTitle = '注册账号'
    loginModal.value.registerText = '直接登录'
  }
}
function goPages(e) {
  if (e === 1 || e === 2 || e === 3) {
    if (localStorage.getItem('token')) {
      if (e === 1 || e === 2) {
        reload()
        router.push({ path: '/user', query: { current: 0 } })
      } else {
        reload()
        router.push({ path: '/user', query: { current: 9 } })
      }
    } else {
      ElMessage.warning('您未登录,请先登录')
      loginModal.value.visible = true
      loginModal.value.loginVisible = true
    }
  } else if (e === 0) {
    router.push('/')
  } else if (e === 4) {
    if (localStorage.getItem('token')) {
      getChatService({ type: 1 }).then((res) => {
        if (res.code == 0) {
          let to = router.resolve({ path: '/service', query: { account: res.data.id } })
          window.open(to.href, '_blank')
        }
      })
    } else {
      loginModal.value.visible = true
      loginModal.value.loginVisible = true
    }
  }
}
const LogOut = () => {
  LogOutDialog.value = true
}
const confirm = () => {
  localStorage.clear()
  store.commit('user/setLoginStatus', false)
  loginStatus.value = false
  LogOutDialog.value = false
}
onMounted(() => {
  if (localStorage.getItem('token')) {
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
    loginStatus.value = true
  }
})
</script>
<style scoped lang="less">
.app-topnav {
  // padding: 0 360px;
  background: #262626;

  .wrap {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
        cursor: pointer;
      }
      p:not(:first-child) {
        margin-left: 20px;
      }
    }
    .right {
      ul {
        display: flex;
        height: 53px;
        justify-content: flex-end;
        align-items: center;
        li {
          a {
            padding: 0 15px;
            color: #cccccc;
            line-height: 1;
            display: inline-block;
            i {
              font-size: 14px;
              margin-right: 2px;
            }
            &:hover {
              color: #00b6fa;
            }
          }
          ~ li {
            a {
              border-left: 2px solid #666;
            }
          }
        }
      }
    }
  }
}
</style>
